@import "../../App";

.header-page-container {
  background-color: $color-white;
  z-index: 20;
  width: 100%;
}

.login-context {
  @include font-style($font-main, fontWeight('meduim'), 16px, 19px);
  padding: 0 30px;

  .userEmail {
    @include font-style($font-desc, fontWeight('meduim'), 16px, 18px);

  }
}

.header-page-main {
  position: relative;

  @include flex(space-between, center);
  margin: 0 3% 0 3%;

  @media screen and (min-width: 1025px) {
    margin: 0 80px 0 80px;
  }

  padding: 25px 0;

  .logo-container {
    cursor: pointer;
    @include flex(center, center);
    gap: 20px;
  }
}

.context-title {
  @include flex(center, start, column);
}

//.event-title{
//  font-size: 18px;
//  color: $wtt-red;
//  font-weight: 800;
//}
//
//.event-subtitle{
//  font-size: 16px;
//  color: $wtt-red;
//  font-weight: 800;
//}
//
//.event-title-link{
//  font-size: 32px;
//  color: $wtt-red;
//  font-weight: 800;
//
//  &:hover {
//   text-decoration: underline;
//   cursor: pointer;
//  }
//}

.header-page-logo {
  @include flex(space-between, center);
  gap: 5px;
}

.header-page-logo-img {
  max-width: 215px;
  max-height: 56px;
}

.context-out {
  @include flex(center, none, column);
  width: 100%;
}

.context-out-btn {
  //@include button-hover;
}

.header-page-logo-img:hover {
  cursor: pointer;
}

.edit-profile {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid silver;
  margin: 10px 0 10px;
  outline: none;
  background-color: rgba(40, 40, 40, .08);
  cursor: pointer;
}

.header-page-actions {
  @include flex(center, center);
  gap: 76px;

  p {
    @include font-style($font-main, fontWeight('medium'), 16px, 18px);
  }

  .header-logout-container {
    @include flex(space-between, none, column);
    height: 100%;
    width: 100%;

    p {
      line-height: 31px;
    }
  }

  .header-login {
    @include flex(center, center);
    gap: 10px;

    .header-login__status {
      @include flex(start, center);
      gap: 20px;
    }

    .header-login-container {
      @include flex(none, center);

      .header-popover {
        @include flex(none, center);
        gap: 15px;

        .header-popover-text {
          @include flex(none, flex-start, column);
          margin-left: 15px;
        }
      }
    }

    .userEmail,
    .userName,
    .userEmail:hover,
    .userName:hover {
      color: rgba(18, 22, 31, 0.48);
    }


    .userName {
      color: $color-black !important;
      font-weight: 600;
    }
  }

}

.user-name-near-avatar {
  color: $color-black;
  font-weight: fontWeight('regular');
  @include hyphens;


}

.user-name-near-avatar:hover {
  color: $color-black;
}

.logout {
  padding: 16px 32px;
  margin: 4px 4px;
  @include font-style($font-main, fontWeight('regular'), 16px, 24px);
  cursor: pointer;
}

.userName {
  color: #202124;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
}

.userEmail {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: fontWeight('regular');
  color: #5f6368;
  align-self: start;
}

.context-list {
  font-size: 15px;
}

.header-login:hover {
  cursor: pointer;
}

.list-container {
  padding-top: 7px;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 13px;
}

.logout:hover {
  background: $color-background;
  color: $color-blue;
}


.black-rounded-btn {
  background-color: $color-black;
  border-radius: 9999px;
  padding: 18px 52px;
  color: $color-background !important;
}

.black-rounded-btn-entry {
  background-color: $color-black;
  color: $color_one !important;
  border-radius: 9999px;
  padding: 18px 52px;
  text-align: center;
  font-size: 36px;
}

.black-rounded-btn:hover {
  @include button-hover;
}

.black-rounded-btn-entry:hover {
  @include button-hover;
}

.header-page-actions p:hover:not(:last-child) {
  cursor: pointer;
  color: $color-blue;
}

.mobile-head,
.mobile-head2 {
  display: none;
}

.desktop-head {
  display: block;
}

@media (min-width: mediaWight(desktopSm)) and (max-width: mediaWight(desktopXl)) {

  .logout {
    padding: 12px 28px;
    margin: 4px 4px;
    @include font-style($font-main, fontWeight('regular'), 14px, 24px);
    cursor: pointer;
  }

  .mobile-head,
  .mobile-head2 {
    display: none;
  }

  .desktop-head {
    display: block;
  }

  .header-page-main {
    max-height: 9vh;
  }

  .header-left-side,
  .header-page-actions {
    gap: 48px;
  }

  .header-page-actions p {
    @include font-style($font-main, fontWeight('medium'), 14px, 18px);
  }

  .header-left-side {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .header-left-side p {
    @include font-style($font-main, fontWeight('medium'), 14px, 18px);

  }

  .black-rounded-btn {
    padding: 14px 40px;
  }

  .header-page-logo-img {
    max-width: 160px;
    max-height: 56px;
  }

  .header-login {
    img {
      width: 48px;
      height: 48px;
    }

  }
}

@media (max-width: mediaWight(desktopSm)) {

  .header-page-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    z-index: 200;
    box-sizing: border-box;
    background: #fff;
    width: 100%;
    height: 100%;
    padding: 48px 24px;
    gap: 48px;
    position: fixed;

    .header-login {
      @include flex(center, start, column);
      gap: 24px;
    }

  }

  .mobile-head,
  .mobile-head2 {
    display: block;
  }

  .desktop-head {
    display: none
  }

  .header-page-main {
    padding: 6px 0;
  }


  .mobile-header-side {
    display: block !important;
  }

  .header-page-main {
    @include flex(space-between, center);
  }

  .header-page-logo-img {
    max-width: 160px;
    max-height: 56px;
  }

  //.event-title{
  //font-size: 16px;
  //color: $wtt-red;
  //font-weight: 800;
  //  a:link {
  //    text-decoration: none;
  //  }
  //}
}

@media (max-width: 1200px) {
  .event-title-link {
    font-size: 18px;
  }
}

@media (min-width: mediaWight(mobileXl)) and (max-width: mediaWight(desktopSm)) {

  .event-title-link {
    font-size: 20px;
  }
  .black-rounded-btn-entry {
    font-size: 30px;
  }
}

@media (max-width: mediaWight(mobileXl)) {
  .event-title-link {
    font-size: 20px;
  }
  .black-rounded-btn-entry {
    font-size: 16px;
    padding: 17px 30px;
  }
}

.logo-wrap {
  display: flex;
  align-items: center;
  gap: 32px;
  cursor: pointer;
}

.logo-description {
  color: $color-header-text;
  font-family: $font-main;
  font-size: 1.7rem;
  font-weight: 400;
}

.header-nav-items-wrap {
  @include flex(center, center, column);
  gap: 24px;
}

.headerItemLink {
  color: var(--wl-colors-main-black, #282828);
  font-family: $font-main;
  font-size: 1rem;
  //font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.headerItemLinkActive {
  color: $color-header-text;
}

.hidden-body {
  overflow: hidden;
}

.back-icon {
  width: fit-content;
  height: 16px;
  cursor: pointer;

  padding: 12px;
  background-color: #fff;
  border-radius: 50%;
}

.header-title {
  color: #282828;
  font-size: 24px;
  font-weight: 600;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.searchWrap {
  display: flex;
  align-items: center;
}