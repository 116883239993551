@import "../../App";

.footer-page-container {
  width: 100%;
  box-sizing: border-box;


  @include flex(space-around, center);
  flex-direction: column;
  background-color: $color_six;
  color: white;

  padding: 62px 100px 20px 100px;
}

.footer-page-logos {
  //min-height: 200px;
  display: flex;
  //padding: 20px;
  background-color: white;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  img {
    width: 130px;
  }
}


.footer-page-links {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  border-bottom: 1px solid #fff;

  @media screen and (min-width: 1024px) {
    justify-content: space-between;
  }

  &__item {
    display: block;

    color: #fff;
    font-family: $font-main;
    font-size: 16px;
    //font-weight: 500;
    line-height: 170.625%;
    text-decoration: none;

    cursor: pointer;
    text-align: center;

    @media screen and (min-width: 1024px) {
      text-align: start;
    }

    &--modile {
      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      margin-right: 100px;

      @media screen and (min-width: 1024px) {
        display: block;
      }
    }
  }
}

.footer-page-logo {
  @include flex(space-between, center);
  gap: 5px;
}

.footer-page-actions {
  width: 100%;
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 64px;
    justify-content: flex-end;
  }

  &--mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px !important;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
}

.footer-page-actions p {
  @include font-style($font-main, 400, 16px, 20px);
}

.footer-page-copyright {
  width: 100%;
  padding-top: 30px;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 35px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }

  @include font-style($font-main, 300, 16px, 20px);
}

//Mobile Responsive

@media (max-width: 576px) {
  .footer-page-links {
    display: block;

    .footer-page-actions {
      @include flex(start, center);
      flex-direction: column;
      gap: 48px;
    }
  }
  //.footer-page-copyright {
  //  margin-top: 150px;
  //}
}

@media (min-width: 768px) {
}

.logo-with-address-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 35px;

  @media screen and (min-width: 1024px) {
    align-items: start;
  }
}

.footer-page-links-wrap {
  @include flex(center, center);
  gap: 30px;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    gap: 90px;
  }
}

.social-media-wrap {
  display: flex;
  gap: 35px;
}

.copyrighting-mobile {
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.footer-links {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;

  &--mobile {
    text-align: center;
  }
}
