@import "../../../App";

.recents-events-head .title {
  @include font-style(#000, 700, 48px, 58px);
  color: #000;
}

.day, .month {
  text-align: center;
}


.recents-events-main {
  width: 72%;
  margin: auto;
  padding-top: 64px;

  &__pagination {
    position: relative;
  }
}

.recents-events-head {
  @include flex(space-between, center);
  padding-bottom: 46px;
}

.recents-events-head-option {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
}

.recents-events-cards-main {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 48px;
}

.recents-events-card {
  background-color: $bgColor-event;
  width: 32.1%;
  height: auto !important;
  margin-bottom: 24px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;

  img:first-child {
    object-fit: cover;
  }

  &:hover {
    background-color: #EFF2FF;
    cursor: pointer;

    .event-desc-decoration {
      background: none;
    }
  }
}

.recents-events-card-img {
  width: 100%;
  aspect-ratio: 358 / 201;
  border-radius: 12px 12px 0 0;

  cursor: pointer;

  &--bg {
    max-width: 100%;
    height: auto;


    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 180px;
    }
  }
}

.recents-event-card-detail {
  padding: 42px 36px 48px 36px;
  display: flex;
  gap: 20px;

  .month {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }

  .day {
    color: #000;
    font-size: 24px;
    font-weight: 700;
  }

  .day-line {
    background: $color-events-day-line;
    width: 3px;
    margin: 24px 13px;
    height: 50px;
    display: block;
  }

  .time {
    color: #000;
    font-size: 18px;
    padding-top: 16px;
  }

  .event-heading {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: block;
    max-width: 210px;
  }

  .event-desc {
    @include font-style($font-main, 400, 16px, 28px);
    @include para-color;
    position: relative;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    //background: linear-gradient(to top, rgba(135, 133, 133, 0.00) 0%, rgba(255, 255, 255, 0.21) 47.92%, #FFF 100%);
  }
}

.event-desc-decoration {
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(135, 133, 133, 0.00) 0%, rgba(255, 255, 255, 0.21) 47.92%, #FFF 100%);
}

.recents-event-card-detail div {
  display: flex;
  flex-direction: column;
  // gap: 12px;
}

.recents-events-tags {
  @include flex(start, center);
  gap: 24px;

  .tag {
    @include font-style($font-main, 500, 16px, 16px);
    @include tag-color();
    background-color: $bgColor-events-tags;
    @include flex(center, center);
    gap: 12px;
    padding: 19px 24px;
    border-radius: 9999px;

    .recents-events-tags-svg {
      fill: $color-events-tags-svg;
    }

    .recents-events-tags-svg:hover {
      cursor: pointer;
      fill: $color-events-tags-svg-hover;
    }
  }
}

.date-pick {
  padding: 7px;
  border: 1px solid lightgray;
  border-radius: 3px;
}

.recents-event-speakers {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-top: 8px;
}

.recents-event-speakers .speaker-dp {
  border-radius: 50px;
}

.recents-event-speakers .speakers-overlay-main:not(:first-child) {
  margin-left: -14px;
}

// .recents-event-speakers img:hover {
//   box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
// }

//Laptops Responsive

@media (min-width: 1024px) and (max-width: 1768px) {
  .recents-events-card {
    width: 31.25%;
    height: 620px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .recents-events-card {
    width: 48%;
    height: 620px;
  }
}

@media (min-width: 1024px) and (max-width: 1850px) {
  .recents-events-main {
    width: 82%;
  }

  .recents-event-card-detail {
    padding: 42px 36px 48px 36px;
    gap: 12px;

    .month {
      color: #000;
      font-size: 16px;
      font-weight: 600;
    }

    .day {
      @include font-style($font-second, 700, 20px, 28px);
    }

    .day-line {
      background: $color-events-day-line;
      width: 3px;
      margin: 24px 13px;
      height: 50px;
      display: block;
    }

    .time {
      @include font-style($font-main, 400, 16px, 20px);
    }

    .event-heading {
      @include font-style($font-second, 700, 20px, 28px);
    }

    .event-desc {
      @include font-style($font-main, 400, 14px, 28px);
    }
  }
}

.recent-event-load-more {
  text-align: center;
  align-items: center;
  margin-top: 48px;
}

.recent-event-load-more button {
  cursor: pointer;
  border: none;
  background: $bgColor-event-load-more-button;
  display: block;
  width: max-content;
  height: auto;
  color: $color-events-load-more-button;
  margin: 0 auto;
  border-radius: 9999px;
  padding: 12px 36px 10px;
  transition: all 0.4s ease-in-out;
  @include font-style($font-main, 500, 14px, 28px);
}

.recent-event-load-more button:hover {
  transform: scale(1.05);
}


//Tablets Responsive

@media (min-width: 768px) and (max-width: 1023px) {

  .recents-events-head {
    .title {
      @include font-style($font-second, 800, 44px, 58px);
    }
  }

  .recents-events-main {
    width: 85%;
  }

  .recents-events-cards-main {
    gap: 2%;
  }

  .recents-events-card {
    width: 49%;
    height: 520px;
  }
}

//Mobile Responsive

@media (max-width: 767px) {

  .recents-events-cards-main {
    margin: 24px auto;
  }

  .recents-events-card {
    width: 100%;
    height: 520px;
  }

  .recent-event-load-more {
    margin-top: 24px;
  }

  .recent-event-load-more p {
    width: 100%;
    border-radius: 6px;
    padding: 10px 0;
  }

  .recents-events-head {
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;

    .title {
      @include font-style($font-second, 800, 40px, 58px);
    }
  }

  .recents-events-main {
    width: 90%;
    margin: auto;
  }

  .recents-events-head-option {
    width: 100%;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .recents-events-tags {
    @include flex(start, center);
    overflow-y: scroll;
    scrollbar-width: none;
    gap: 16px;

    .tag {
      @include font-style($font-main, 500, 14px, 16px);
      padding: 12px 20px;

      span {
        width: max-content;
      }
    }
  }

  .recents-events-tags::-webkit-scrollbar {
    display: none;
  }
}

.lyrics-button-wrap {
  width: fit-content;
  margin: auto auto 48px;
}

.lyrics-button {
  padding: 16px 24px;

  width: fit-content;

  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;

  text-align: center;
  cursor: pointer;

  &:hover {
    border-radius: 6px;
    padding: 15px 23px;
    border: 1px solid rgba(76, 111, 255, 0.08);
    background: rgba(76, 111, 255, 0.08);
  }
}

.card-description-wrap {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.share-icon {
  position: absolute;
  right: 18px;
  top: 38px;

  padding: 5px 8px 2px 5px;

  background-color: #f1f3ff;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #c4c4c4;
  }

  &--download {
    top: 80px;
  }
}

.share-icon:hover + .social-links {
  display: block;
}

.social-links {
  display: none;
  background-color: red;
}

.navigation_button {
  display: block;
  width: 100%;
  padding: 24px;
  margin: 40px auto 0;

  border-radius: 8px;
  background: #A5CDF2;

  color: #282828;
  font-family: Helvetica Neue;
  font-size: 20px;
  font-weight: 500;

  border: none;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    width: 330px;
  }

  &--white {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: #fff;
  }
}

.navigation_button {
  display: block;
  width: 100%;
  padding: 24px;
  margin: 40px auto 0;

  border-radius: 8px;
  background: #A5CDF2;

  color: #282828;
  font-size: 20px;
  font-family: Helvetica Neue;
  font-weight: 500;

  border: none;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    width: 330px;
  }

  &--white {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: #fff;
  }
}

.social-links-container {
  width: fit-content;
  padding: 13px;

  position: absolute;
  right: 8px;
  top: 65px;
  z-index: 2;

  &--download {
    top: 105px;
  }
}

.social-links-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: fit-content;
  padding: 16px 24px;

  background-color: #FFFFFF;

  &--download {
    top: 115px;
  }

  &--pt {
    padding: 0;
  }
}

.social-links-item {
  color: #000;
  font-size: 16px;
  text-decoration: none;

  display: flex;
  align-items: center;
  gap: 16px;

  cursor: pointer;

  &--download {
    display: flex;
    gap: 15px;
    box-sizing: border-box;
    align-items: center;
    padding: 8px;
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 58.25%;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;

    &::-webkit-scrollbar {
      width: 0.5em; /* Adjust as needed */
      height: 0.5em; /* Adjust as needed */
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 0.5em; /* Adjust as needed */
    height: 0.5em; /* Adjust as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border: none !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  background: #F3F4F8 !important;
}

.react-datepicker {
  padding: 19px !important;
  background: #F3F4F8 !important;
  border: none !important;
  border-radius: 12px !important;
}

.react-datepicker-wrapper {
  width: inherit;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.react-datepicker-filter {
  height: 48px !important;
}

.react-datepicker__current-month {
  background-color: #fff;
  padding: 12px 0;
}

.react-datepicker__day {
  color: var(--wl-colors-main-black, #282828) !important;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;

  &:hover {
    background-color: #D4DEF5 !important;
    border-radius: 50% !important;
  }
}

.react-datepicker__navigation {
  top: 27px !important;
  width: 70px !important;

  transform: scale(0.8);
}

.react-datepicker__day-names {
  margin-top: 8px !important;
  background-color: #fff !important;

  border-radius: 6px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #D4DEF5 !important;
  color: #000 !important;
  border-radius: 50% !important;
}

.react-datepicker__month {
  margin: 0 !important;
  border-radius: 6px !important;
  background-color: #fff !important;

  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.react-datepicker__navigation-icon--next::before {
  border-color: #000 !important;
}

.react-datepicker__navigation-icon--previous::before {
  border-color: #000 !important;
}

.react-datepicker__header {
  padding-top: 0 !important;
  color: #F3F4F8 !important;
  border-bottom: none !important;
  background-color: #F3F4F8 !important;
}

.react-datepicker__current-month {
  border-radius: 6px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #282828 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.react-datepicker__day-names > div {
  color: var(--wl-colors-3rd-black-50, rgba(40, 40, 40, 0.50)) !important;

  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 150% !important;
  text-transform: uppercase !important;
}


.react-datepicker__month-container {
  border: none !important
}

.react-datepicker__day--keyboard-selected {
  background-color: #D4DEF5 !important;
  color: #000 !important;
  border-radius: 50% !important;
}

.react-datepicker__tab-loop .react-datepicker__tab-loop__start {
  display: none !important;
}

.css-ngsesf-MuiFormControl-root {
  margin: 0 !important;
}